<template>
  <div>
    <Header>
      <template v-slot:right-header-component>
        <ProfileDropDown/>
      </template>
    </Header>
    <CandidateProfile/>
  </div>

</template>

<script>
import CandidateProfile from "@/components/candidateProfile/CandidateProfile";
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
export default {
  name: "CandidateProfileView",
  components: {ProfileDropDown, Header, CandidateProfile}
}
</script>

<style scoped>

</style>