<template>
<div data-app>
  <template>
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="open" max-width="600px" persistent>
      <v-card :loading="loading" width="600px">
        <v-skeleton-loader type="card" :loading="pageLoading" v-if="pageLoading"/>
        <div v-else class="tw-p-6">
          <div class=" tw-flex tw-mb-3 tw-justify-between">
            <div class="Title pb-2">
              <div>Comments</div>
            </div>
            <div class="tw-cursor-pointer" @click="$emit('close'), content = false">
              <vue-feather type="x" style="width:19px"/>
            </div>
          </div>
          <div v-if="!get_allInteractionComment.length && !loading">
            <div class="tw-justify-center">
              <h4 class="sub tw-flex">Be the first to make comment.</h4>
            </div>

          </div>

          <div v-else class="scroll px-5">
          <div v-for="(comment, i) in get_allInteractionComment" :key="i">
          <div class='tw-flex align-center pb-2'>
            <v-avatar color='grey darken-3' size='32'>
              <img v-if='comment.image' :src='comment.image' alt='' />
              <v-img
                  v-else
                  class='elevation-6'
                  alt=''
                  src='https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
              ></v-img>
            </v-avatar>
            <div class='ml-3'>
              <div class='name'>{{ comment.full_name }}</div>
            </div>
          </div>

          <div class="comment tw-mb-6">
            <div >
              <h5 class="text" v-html="comment.comment">{{comment.comment}}</h5>
              <p class="mt-n2 mb-n1">{{ formatDate(comment.date_created) }}</p>
            </div>
          </div>

          <div class="tw-flex tw-justify-end mt-n4" v-if="comment.can_delete" @click="remove(comment.id)">
            <vue-feather class='mr-1' type="trash-2" style='width:16px' />
            <span class="sub tw-cursor-pointer">Delete Comment</span>
          </div>
          </div>
            </div>

          <div v-if="!content" class='tw-flex align-center tw-mt-9' >
            <v-avatar color='grey darken-3' size='32'>
              <!--              <img v-if='post.owner_detail && post.owner_detail.image' :src='post.owner_detail.image' alt='' />-->
              <v-img
                  class='elevation-6'
                  alt=''
                  src='https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
              ></v-img>
            </v-avatar>
            <div class='base ml-3'>
              <div @click="content = true">Add Comment...</div>
              <v-divider></v-divider>
            </div>
          </div>

          <div v-if="content">
          <div class='tw-flex align-center py-3'>
            <v-avatar color='grey darken-3' size='32'>
              <!-- <img v-if='post.owner_detail && post.owner_detail.image' :src='post.owner_detail.image' alt='' />-->
              <v-img
                  class='elevation-6'
                  alt=''
                  src='https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
              ></v-img>
            </v-avatar>
            <div class='ml-3'>
              <div class='name text-capitalize'>{{userData.name}}</div>
            </div>
            </div>
            <div class='editor'>
              <vue-editor
              v-model="comment"
              id="message"
              :editor-toolbar="customToolbar"
              hide-details>
              </vue-editor>

              <div class="tw-flex tw-justify-end tw-my-2" >
                <v-btn color="#008EEF" depressed :loading="btnLoading" :disabled="!comment" class="icn"  @click.prevent="send">
                  <vue-feather type="send" style="width: 20px; color: #FFFFFF"/>
                </v-btn>
              </div>
              <v-divider></v-divider>
            </div>
          </div>


        </div>
      </v-card>
    </v-dialog>
  </template>
</div>
</template>

<script>

import {VueEditor} from "vue2-editor"
import {mapGetters} from "vuex";
import {createInteractionComment} from "@/services/api/APIService";

export default {
  name: "InteractionModal",
  components:{VueEditor},
  props:{
    open:[Boolean],
  },
  data(){
    return{
      loading: false,
      btnLoading: false,
      can_delete: false,
      comment:'',
      pageLoading: false,
      image:'',
      id: null,
      content: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          {align: ""},
          {align: "center"},
          {align: "right"},
          {align: "justify"}
        ],
        [
          {list: "ordered"},
          {list: "bullet"},
        ],
        ["link"],
        [{size: ["small", false, "large", "huge"]}]
      ],
    }
  },
  watch: {
    open: {
      async handler(val){
        if(val){
          console.log(val)
          this.pageLoading = true
          let data = {
            application: this.$route.query.applicant_id,
            site_name: this.$route.query.site_name
          }
          await this.$store.dispatch('get_InteractionComment', data)
          this.pageLoading = false
        }
      },
      immediate: true
    }
  },
  computed:{
    ...mapGetters(['get_allInteractionComment']),
    userData(){
      const user = JSON.parse(sessionStorage.getItem('userData'))
      return user
    },
  },
  methods:{
    async send(){
      this.btnLoading = true
      await createInteractionComment({
        comment:this.comment,
        application: +this.$route.query.applicant_id,
        site_name: this.$route.query.site_name
      }).then(res=>{
        let newComment = this.get_allInteractionComment
        newComment.unshift(res.data)
         this.$store.commit('set_allInteractionComment', newComment)
        this.comment = ''
        this.content = false
        this.btnLoading = false
      })
          .catch(err => {
            console.log(err)
            this.btnLoading = false
          })
    },
    formatDate(date) {
      if (!date) return null;
      const newDate = date.split('T')[0]
      const [year, month, day] = newDate.split("-");
      return `${month}/${day}/${year}`;
    },

    remove(id){
      this.loading = true
      let data={
        id: id,
        application:+this.$route.query.applicant_id,
        site_name: this.$route.query.site_name
      }
      console.log(data)
      this.$store.dispatch('deleteSingleComment',data )
          .then(()=>{
            this.$store.commit('removeComment', id)
            this.loading = false
          })
      .catch(err=>{
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.comment{
  padding: 10px 16px;
  gap: 16px;
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  border-radius: 8px;
}
.Title{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #1E323F;
}
.name{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #001343;
}
.text{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}
p{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9CABB5;
}
.icn{
  display: flex;
  width: 60px;
  height: 40px;
  /*background: #D0DCE4;*/
  border-radius: 8px;
}
.sub{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #475661;
}
.base{
  width: 550px;
  background: #FFFFFF;
}
.scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 4px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 10px !important;
  border-radius: 4px;
}
</style>