import {mapState} from "vuex";
import {viewFile} from "@/services/storage/aws";

export default{
    data:()=>({
        image:''
    }),
    computed:{
        ...mapState(['paymentDetails'])
    },
    watch:{
        paymentDetails:{
            handler: function (val){
                if(!val.payment_proof)return
                viewFile({url:val.payment_proof,context: this})
            },
            deep: true
        }
    }
}