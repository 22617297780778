<template>
  <v-dialog v-model="successModal" max-width="636">
    <div class="addSuccessModal tw-p-6">

      <div class="tw-flex tw-justify-end">
        <v-btn icon @click="$emit('close-submit')">
          <vue-feather type="x" class='tw-pt-1' @click="closeModal()"/>
        </v-btn>
      </div>

      <div class="text-center tw-flex tw-flex-col align-center">
        <div class="tw-mb-7">
          <!--        <img :src="successImg"  alt=""/>-->
          <svg width="163" height="163" viewBox="0 0 163 163" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="163" height="163" rx="81.5" fill="#BFFFDD"/>
            <rect x="19" y="19" width="126" height="126" rx="63" fill="#28E17E"/>
            <path d="M98.6663 68.8008L75.7497 91.7174L65.333 81.3008" stroke="#BFFFDD" stroke-width="5"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>

        <div class="successText tw-mb-6">You have added {{$route.query.name}} into this cohort </div>

        <div class="tw-mb-10">
          <BaseButton button-text="Okay" @click="closeModal()"/>
        </div>
      </div>

    </div>

  </v-dialog>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
export default {
  name: "AddToCohortSuccessModal",
  components: {BaseButton},
  data () {
    return {
      // successImg: require(`@/src/assets/Success icon.png`)
    }
  },
  props: {
    successModal: {Boolean}
  },
  methods: {
    closeModal(){
      this.$emit('modalClose')
    },
  }
}
</script>

<style scoped>
.addSuccessModal {
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.successText {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #001343;
}

</style>