<template>

  <v-dialog v-model="addToACohort" max-width="485">
    <div class="addModal tw-p-6 tw-pt-10">
      <div class='tw-flex justify-space-between'>
        <h4>Add to a Cohort</h4>
        <v-btn icon @click="$emit('close-submit')">
          <vue-feather type="x" class='tw-pt-1' @click='$emit( `close`)'/>
        </v-btn>
      </div>

      <div class="warnText tw-w-72 tw-mt-20 md:tw-ml-20 text-center align-center">
        Are you sure you want to add this applicant to this cohort?
      </div>

      <div class="tw-flex tw-justify-center tw-space-x-5 tw-mt-20 tw-mb-6">
        <BaseButton button-text="Cancel" outlined @click='$emit( `close`)'/>
<!--        <BaseButton button-text="Add" @click="openSuccessModal"/>-->
        <BaseButton button-text="Add" :loading="loading" @click="assignToCohort"/>
      </div>

    </div>
    <AddToCohortSuccessModal :successModal="successAdd" @modalClose="closeSuccessModal"/>

  </v-dialog>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import AddToCohortSuccessModal from "@/components/candidateProfile/interviewReport/AddToCohortSuccessModal";
import {assignApplicantToCohort} from "@/services/api/APIService";
export default {
  name: "AddToACohort",
  components: {AddToCohortSuccessModal, BaseButton},
  data () {
    return {
      successAdd: false,
      loading: false,
    }
  },
  props: {
    addToACohort: {Boolean},
    id: [Number, String],
  },
  methods: {
    closeSuccessModal () {
      this.successAdd = false
    },
    assignToCohort(){
      this.loading = true
      assignApplicantToCohort({
        email: this.$route.query.email,
        site_name: this.$route.query.site_name,
        classroom_id: this.id
      }).then(res=>{
            console.log(res)
            this.loading = false
        this.successAdd = true
        this.$emit('close')
          })
          .catch((e)=>{
            console.log(e)
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>
.addModal {
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}

h4 {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1E323F;
}

.warnText {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /*text-align: center;*/
  color: #1E323F;
}

</style>