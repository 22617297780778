<template>
  <div v-if="admin.full_name !== '- -' && admin.full_name && getUserDetails.email !== admin.admin_user_email" :class="selectedAdmin.includes(admin.admin_user_email)?'selected':''" class="tw-flex  tw-my-6 tw-justify-between tw-items-center tw-cursor-pointer" @click="$emit('select',admin.admin_user_email)" >
    <div class="tw-flex ">
      <v-avatar color="#EEF8FF" size="30" v-if="admin.admin_user_email" class="initials">{{admin.full_name.charAt(0) +''+ admin.full_name.charAt(1)}}</v-avatar>
      <span class="name tw-ml-4">{{admin.full_name}}</span>
    </div>
    <div v-if="selectedAdmin.includes(admin.admin_user_email)">
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12.9297" r="12" fill="#8F47F4"/>
        <path d="M6.86328 13.7967L9.71097 16.6418L17.138 9.21484" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
    </div>

  </div>

</template>

<script>
export default {
name: "AdminDetails",
  props:{
    admin:[Object],
    selectedAdmin:[Array]
  },
  computed:{
    getUserDetails(){
      return JSON.parse(sessionStorage.getItem('userData')) || null
    }
  }
}
</script>

<style scoped>
.name{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.initials{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #008EEF;
  text-transform: uppercase;
}

.selected{
  background: #FAF7FF;
  border-radius: 8px;
  transition: all .5s ease;
  padding: 10px;
}
</style>
