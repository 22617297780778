<template>
  <div class="tw-mt-14 tw-mb-8">
    <h2 class="type tw-mb-2">Invite Interviewer</h2>
    <small>Select admins to join you in interviewing the candidate.</small>
    <div class="tw-mt-5">
      <v-text-field
          id="link"
          v-model="search"
          class=""
          label="Search"
          solo
      />
    </div>
    <div class="scroll md:tw-w-min-500">
      <div v-if="adminList && adminList.length && !search " class="tw-mr-8">
        <div v-for="(admin,i) in adminList" :key="i">
          <AdminDetails :admin="admin" :selected-admin="selectedAdmin" @select="selectAdmin"/>
        </div>
      </div>
      <div v-else-if="!adminList.length">
        <span class="no-admin">You are the only admin in this organization.</span>
      </div>
      <div v-else-if="!searchResult.length && search">
        <span class="no-admin">No result matches your search</span>
      </div>
      <div v-else class="tw-mr-8">
        <div v-for="(admin,i) in searchResult" :key="i">
          <AdminDetails :admin="admin" :selected-admin="selectedAdmin" @select="selectAdmin"/>
        </div>
      </div>
    </div>
    <div :class="checkRoute?'tw-my-24':'tw-my-8'" class="tw-flex tw-items-center">
      <v-spacer v-if="!checkRoute"></v-spacer>
      <div>
        <v-btn v-if="checkRoute" class="later tw-mr-6" text @click="goToApplicantDashboard">
          I’ll do this later
        </v-btn>
        <BaseButton v-else :outlined="true" button-text="Back" class="tw-mr-6" width="87px"
                    @click="$emit('close-interview')"></BaseButton>
      </div>

      <BaseButton :disabled="!selectedAdmin" :loading="loading" button-text="Send Invite" width="135px"
                  @click="sendInvite"></BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {getCompanyAdmins, inviteAdminForInterview} from "@/services/api/APIService";
import AdminDetails from "@/components/interview/AdminDetails";

export default {
  name: "InviteAdminForInterview",
  components: {AdminDetails, BaseButton},
  data() {
    return {
      adminList: [],
      selectedAdmin: [],
      search: '',
      searchResult: [],
      loading: false
    }
  },
  props: {
    details: [Object]
  },
  watch: {
    search: function (newValue, oldValue) {
      console.log(oldValue)
      this.searchResult = [...this.adminList.filter(pool => (pool.admin_user_email.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
      console.log(this.searchResult)
    }
  },
  created() {
    this.getAllAdmin()
    console.log(this.details)
  },
  computed: {
    checkRoute() {
      return this.$route.query.interviewId || null
    }
  },
  methods: {
    selectAdmin(name) {
      let index = this.selectedAdmin.indexOf(name);
      if (index > -1) {
        this.selectedAdmin.splice(index, 1);
      } else {
        this.selectedAdmin.push(name)

      }
    },
    goToApplicantDashboard() {
      let queryDetails = this.$route.query
      this.$router.push({
        name: 'CandidateProfile',
        params: {id: queryDetails.id},
        query: {jobId: queryDetails.jobId, email: queryDetails.email}
      })

    },
    sendInvite() {
      if (this.selectedAdmin.length) {
        this.loading = true
        let id
        if (this.checkRoute) {
          id = this.$route.query.interviewId
        } else {
          id = +this.details.interview_ids[0]
        }
        let data = {
          id: id,
          interview_admins: this.selectedAdmin,
          name: this.$route.query.name
        }
        inviteAdminForInterview(data)
            .then(res => {
              console.log(res)
              if (this.checkRoute) {
                this.goToApplicantDashboard()
              } else {
                this.$emit('close')
              }
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => this.loading = false)
      } else {
        this.$displaySnackbar({
          message: 'Select the admin you want to invite',
          success: false
        })
      }
    },
    getAllAdmin() {
      getCompanyAdmins(this.$siteName())
          .then(res => {
            this.adminList = res.data
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
    }
  }
}
</script>

<style scoped>
.type {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #001343;
}

.later {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #475661;
  text-transform: none;

}

.no-admin {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;

}

.scroll {
  max-height: 150px;
  overflow-y: auto;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

</style>
