<template>
  <div>
    <div v-if="!loader">
      <div  class="tw-flex tw-px-5 md:tw-px-2.5 tw-w-10/12 mx-auto align-center	tw-mt-28 tw-justify-between">
        <div>
          <div v-if="!$route.query.jobId">
            <span class="tw-mr-1 tw-text-enumBlue route" @click="goToProgram">Programs</span>/<span class="tw-mr-1 tw-ml-1 tw-text-enumBlue route" style="white-space: nowrap" @click="goToProgramName">{{$route.query.program_title}}</span>/<span class="tw-ml-1 route">Applicant</span>
          </div>
          <summary-of-candidate-details v-if="$route.query.jobId" :details="candidateDetails"/>

        </div>
        <span v-if="!$route.query.jobId" class="status" :style="styleApplicantStatus">{{statusText(candidateDetails.status)}}</span>
        <span v-else class="status" :style="styleApplicantStatus">{{statusText(candidateDetails.status)}}</span>
      </div>
      <div class="tw-w-10/12 mx-auto tw-px-5">
        <div class="mt-8">
          <span class="tw-text-enumDarkBlue mr-2 name">{{$route.query.name}}</span>
        </div>
        <div class="email">{{$route.query.email}}</div>
      </div>
      <div class="profile ">
        <div class="overallPerformanceCard">
          <v-row class="score-card">
            <span class="text-uppercase tw-mb-4 tw-tracking-widest score-title">overall performance</span>
              <div class="d-flex justify-center text-center flex-column">
                <span class="score">{{totalPercentile?totalPercentile+'%':'Not available'}}</span>
                <span class="score-title">Aptitude</span>
              </div>
          </v-row>
          <div>
            <div>
              <span class="actions mb-2">ACTIONS</span>
              <div v-if="$route.query.from !== 'admin-workspace-programs-programdetails'&& $route.query.from !=='admin-interview'"
                   class="d-flex justify-space-between otherRoutes mt-3" @click="showCvModal(candidateDetails)">
                <span>View Candidate's CV</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
              <div v-if="candidateDetails.status === 'qualified'|| candidateDetails.status ==='applied' || candidateDetails.status === 'taken_assessment' || candidateDetails.status === 'shortlisted'|| candidateDetails.status === 'awaiting_interview' || candidateDetails.status ==='filled_questionnaire' "
                   class="d-flex justify-space-between otherRoutes mt-3" @click="inviteForInterview">
                <span>Invite for Interview</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
              <div  v-if="$route.query.from "
                    class="d-flex justify-space-between otherRoutes mt-3" @click="openPayment">
                <span>View Payment Receipt</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
              <div v-if="$route.query.from "
                   class="d-flex justify-space-between otherRoutes mt-3" @click="openComment">
                <span>Interaction Comments</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
              <div  v-if="$route.query.from && candidateDetails.status === 'invited_for_interview'"
                    class="d-flex justify-space-between otherRoutes mt-3" @click="gotoStart">
                <span>Start Interview</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
              <div  v-if="$route.query.from && candidateDetails.status === 'interviewing'"
                    class="d-flex justify-space-between otherRoutes mt-3" @click="gotoStart">
                <span>Join Interview</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
                <div v-if="$route.query.from && candidateDetails.status==='qualified'||candidateDetails.status==='shortlisted'||
                candidateDetails.status==='admitted'|| candidateDetails.status==='offered_admission'||
                candidateDetails.status==='offer_accepted'||candidateDetails.status==='enrolled'"
                     class="d-flex justify-space-between otherRoutes mt-3" @click="addToCohort = true">
                  <span>Add To Cohort</span>
                  <img alt="arrow" src="../../assets/arrowRight.svg">
                </div>


              <div  v-if="$route.query.from && candidateDetails.status === 'interviewed'||candidateDetails.status ==='checked_in'||
              candidateDetails.status ==='offered_admission' ||candidateDetails.status ==='offer_accepted' ||
              candidateDetails.status ==='enrolled' || candidateDetails.status ==='offer_rejected'"
                    class="d-flex justify-space-between otherRoutes mt-3" @click="goToProgramInterview">
                <span>View Interview Report</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
              <div  v-if="candidateDetails.status==='qualified'||candidateDetails.status ==='interviewed'||
              candidateDetails.status === 'taken_assessment' || candidateDetails.status ==='filled_questionnaire' || candidateDetails.status ==='invited_for_interview'" class="d-flex justify-space-between otherRoutes mt-3" @click="makeOfferModal = true">
                <span>{{$route.query.from?'Offer Admission':'Make Offer'}}</span>
                <img alt="arrow" src="../../assets/arrowRight.svg">
              </div>
            </div>
            <div>
              <span class="actions mb-2" v-show="candidateDetails.status === 'rejected'">REJECTION DETAILS</span>
              <div v-if="!$route.query.from">
                <div
                    v-if=" candidateDetails.status==='qualified'||candidateDetails.status==='applied' || candidateDetails.status==='rejected' || candidateDetails.status ==='interviewed'"
                    class="d-flex justify-space-between mt-3  otherRoutes">
                  <span @click.stop="rejectDialog = true">Reject Applicant</span>
                  <img src="../../assets/arrowRight.svg" alt="arrow">
                </div>
              </div>
              <div  v-else-if="candidateDetails.status === 'offered_employment'|| candidateDetails.status === 'hired' || candidateDetails.status === 'declined' || candidateDetails.status === 'rejected'">
                <div
                    v-if="candidateDetails.status === 'offered_employment'"
                    class="d-flex justify-space-between otherRoutes mt-3">
                  <span>Offer Pending</span>
                  <MoreHorizontalIcon style="color: #9CABB5"/>
                </div>
                <div v-else-if="candidateDetails.status === 'Hired'"
                       class="d-flex justify-space-between otherRoutes mt-3">
                  <span style="color: #0BCA7A">Offer Accepted</span>
                  <UserCheckIcon style="color: #0BCA7A"/>
                </div>
                <div
                    v-else-if="!$route.query.from && candidateDetails.status === 'declined'"
                    class="d-flex justify-space-between otherRoutes mt-3">
                  <span style="color:#FF5353;">Offer Declined</span>
                  <AlertCircleIcon style="color: #FF5353"/>
                </div>
                <div v-else-if="candidateDetails.status === 'rejected'"  class="otherRoutes mt-3">
                  <RejectionDetails />
                </div>
              </div>
              <div v-else>
                <div v-if="!$route.query.from && candidateDetails.status !== 'shortlisted'" class="d-flex justify-space-between otherRoutes mt-3" @click="viewInterviewDetails">
                  <span>View Interview Details</span>
                  <img alt="arrow" src="../../assets/arrowRight.svg">
                </div>
                <div v-if="!$route.query.from && candidateDetails.status === 'interview_scheduled'" class="d-flex justify-space-between otherRoutes mt-3" @click="startInterview">
                  <span>{{ interviewButtonText }}</span>
                  <img alt="arrow" src="../../assets/arrowRight.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-16">
          <DashboardHorizontalTabSliders id="tabs" :tabs="tabData"/>
        </div>
        <div>
          <router-view :psychometricResult="psychometricResult"
                       :personalityResult="personalityResult"
                       :totalPercentile="totalPercentile"
                       :loading="loading"
                       :options="options"
          >

          </router-view>
        </div>
      </div>
    </div>

    <div v-else class="candidateLoader mt-16">
      <Loader/>
    </div>
    <InterviewScheduleSummaryAndStatus v-if="candidateDetails.applicant" :detail="interviewDetails" :dialog="dialog"
                                       :name="candidateDetails.applicant.full_name" @close="closeDialog"
                                       @open-interview="openInvite"/>
    <InviteInterviewModal :dialog1="openInterviewModal" :details="candidateDetails"
                          @close="openInterviewModal=false" @close-interview="closeInvite"/>
    <StartJoinInterviewModal :detail="interviewDetails" :is_invited="isInvited" :start-modal="startModal"
                             :status="candidateDetails.status" @close="startModal=false"/>

    <view-candidate-c-v-modal :dialog="cvModal" @close="showCvModal" :data="candidateDetails" />
    <AssignApplicantToCohortModal :assignDialog="addToCohort" @close="closeAssignModal"/>
    <InteractionModal :open="commentModal" @close="commentModal=false"/>
    <RejectCandidateModal
        @close="rejectDialog = false"
        v-if="!$route.query.from"
        @getProfile="getProfile"
        :candidateDetails="candidateDetails.applicant"
        :job_id="$route.query.jobId" :dialog="rejectDialog"/>

    <MakeOfferModal
        @close="makeOfferModal = false"
        v-if="this.applyStage.includes(this.candidateDetails.status)"
        @getProfile="getApplicantProgramStatus(true)"
        :candidateDetails="candidateDetails.applicant ||{'full_name':$route.query.name}"
        :program_id="$route.query.program_id"
        :job_id="$route.query.jobId"
        :dialog="makeOfferModal"/>

      <ViewPaymentModal :open="paymentModal" @close="closePaymentModal" :status="candidateDetails.status"/>

    </div>
</template>

<script>

import SummaryOfCandidateDetails from "@/components/candidateProfile/summaryOfCandidateDetails";
import DashboardHorizontalTabSliders from "@/components/dashboard/reuseables/DashboardHorizontalTabSliders";
import {
  getCandidateReport,
  getUserProfile,
  getUserProgramInterviewDetails,
  getCandidateProviderReport,
  getProgramApplicationStatus
} from "@/services/api/APIService";
import {getUserInterviewDetails} from "@/services/api/APIService";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import getSiteName from "@/services/utils";
import MakeOfferModal from "@/components/candidateProfile/MakeOfferModal";
import RejectCandidateModal from "@/components/candidateProfile/RejectCandidateModal";
import InterviewScheduleSummaryAndStatus from "@/components/interview/InterviewScheduleSummaryAndStatus";
import InviteInterviewModal from "@/components/interview/InviteInterviewModal";
import StartJoinInterviewModal from "@/components/interview/StartJoinInterviewModal";
import {MoreHorizontalIcon, UserCheckIcon, AlertCircleIcon} from 'vue-feather-icons'
import RejectionDetails from "@/components/candidateProfile/RejectionDetails";
import {mapGetters} from "vuex";
import ViewCandidateCVModal from "@/components/viewAdminJobs/ViewCandidateCVModal";
import AssignApplicantToCohortModal from "@/components/candidateProfile/AssignApplicantToCohortModal";
import {startProgramInterview} from "@/services/api/APIService";
import InteractionModal from "@/components/candidateProfile/InteractionModal";
import ViewPaymentModal from "@/components/candidateProfile/ViewPaymentModal";

export default {
  name: "CandidateProfile",
  components: {
    ViewPaymentModal,
    InteractionModal,
    AssignApplicantToCohortModal,
    ViewCandidateCVModal,
    RejectionDetails,
    RejectCandidateModal, MakeOfferModal, Loader, DashboardHorizontalTabSliders, SummaryOfCandidateDetails,
    UserCheckIcon, AlertCircleIcon, MoreHorizontalIcon, StartJoinInterviewModal, InviteInterviewModal,
    InterviewScheduleSummaryAndStatus
  },
  data() {
    return {
      addToCohort: false,
      paymentModal:false,
      cvModal:false,
      commentModal:false,
      loading:false,
      makeOfferModal: false,
      rejectDialog: false,
      skillResult:[],
      applyStage:['offered_employment','Hired','Declined','Rejected','offered_admission','interviewed', 'taken_assessment'],
      personalityResult:[],
      psychometricResult:[],
      arr: [],
      totalPercentile:0,
      personalityTotalPercentile:0,
      candidateDetails: {},
      loader: false,
      tabs: [
        {
          name: 'Evaluation',
          to: {name: 'Evaluation'}
        },
        {
          name: 'Interview',
          to: {name: 'InterviewActivities'}
        },
        {
          name: 'activity log',
          to: {name: 'Logs',}
        },
      ],
      providerTabs: [
        {
          name: 'Evaluation',
          to: {name: 'Evaluation',query:this.$route.query}
        },
        {
          name: 'Questionnaire Responses',
          to: {name: 'Questionnaire',query:this.$route.query}
        },
        {
          name: 'Enrollment Form Responses',
          to: {name: 'Enrollment',query:this.$route.query}
        },
        {
          name: 'Activity Log',
          to: {name: 'ActivityLog',query:this.$route.query}
        },

      ],
      interviewDetails: {},
      dialog:false,
      openInterviewModal:false,
      action:'',
      startModal:false,
      isInvited:false,
      componentToDisplay:'',
      statusStyle:{
        color:'#431288',
        backgroundColor:'#E2EBFC'
      },
      options: {
        chart: {
          type: 'donut',
        },
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: (val) => {
             let valueFormatted = val / 100;
            return Math.round(this.total * valueFormatted)
          },
        },
        legend: {
          fontFamily: 'DM Sans',
          fontSize: '16px',
          horizontalAlign: 'center',
          itemMargin: {
            horizontal: 10,
            vertical: 8
          },
        },
        markers: {
          shape: 'square'
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: 'hds'
              }
            },
            startAngle: -90,
            endAngle: 90,
            offsetY: 10
          }
        },
        grid: {
          padding: {
            bottom: -120
          }
        },
        responsive: [{
          breakpoint: 380,
          options: {
            chart: {
            },
            legend: {
              position: 'bottom',

            },

          }
        }],

        colors: [ '#FF9900', '#109618', '#3366CC']
      },
    }
  },

  props: {
    candidateOffer: Object
  },
  computed:{
    total(){
      return this.psychometricResult.reduce((a, b) => {
        return (a + b)
      }, 0)
    },
    getLogInUser(){
      return JSON.parse(sessionStorage.getItem('userData')) || null
    },
    tabData(){
      if(this.$route.query.from){
        return this.providerTabs
      } else {
        return this.tabs
      }
    },
    interviewButtonText(){
      let interview_button
      if(this.interviewDetails.invited_by_email===this.getLogInUser.email){
        interview_button = 'Start Interview'
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isInvited = false
      }
      else{
        interview_button = 'Join Interview'
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isInvited = true
      }
      return interview_button
    },
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    styleApplicantStatus(){
      if(this.candidateDetails.status === 'Invited For Interview'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusStyle ={
            color:'#FF7B01',
            backgroundColor:'#FFF4E6'
        }
      }
      else if(this.candidateDetails.status === 'rejected' || this.candidateDetails.status==='interview_declined' || this.candidateDetails.status==='declined'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusStyle ={
          color:'#FF5353',
          backgroundColor:'#FFF3F3'
        }
      }
      else if(this.candidateDetails.status === 'interviewed'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusStyle ={
          color:'#001343',
          backgroundColor:'#E2EBFC'
        }
      }
      else if(this.candidateDetails.status === 'interviewing'|| this.candidateDetails.status === 'payment'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusStyle ={
          color:'#0BCA7A',
          backgroundColor:'#D7FFEE'
        }
      }
      else if(this.candidateDetails.status === 'interview_scheduled'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusStyle ={
          color:'#008EEF',
          backgroundColor:'#EBF5FB'
        }
      }
      else if(this.candidateDetails.status === 'offered-employment' || this.candidateDetails.status === 'hired'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusStyle ={
          color:'#1E323F',
          backgroundColor:'rgba(147, 211, 255, 0.46)'
        }
      }
      else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statusStyle ={
          color:'#431288',
          backgroundColor:'#E2EBFC'
        }
      }
      return this.statusStyle
    },
    ...mapGetters('interview',['getInterviewId']),
    ...mapGetters(['getPaymentDetails','get_allInteractionComment'])

  },

  async created() {
    const {query, params} = this.$route
    this.tabs.forEach(tab => {
      tab.to = {...tab.to, query, params}
    })
    this.getProfile()
    if(this.$route.query.from){
      this.loader = true
      await this.getProgramInterviewData()
      await this.getApplicantProgramStatus()
      this.loader = false
    }
  },

  mounted() {
    sessionStorage.setItem('psychometric',this.psychometricResult)
    sessionStorage.setItem('personality', this.personalityResult)
    this.getApplicantReport()

  },
  methods:{
    closeAssignModal(){
      this.addToCohort = false
    },
    openPayment(){
        this.paymentModal = true
    },
    async openComment() {
      this.commentModal = true
    },
    showCvModal(item){
      console.log(item)
      this.cvModal = !this.cvModal
      if (item){
        this.candidateDetails.email = item.applicant.email
        this.candidateDetails.jobId = item.job_id
      }
      console.log(item)
    },
    statusText(name) {
      if(!name)return
      return name.replace(/_/g,' ')

    },
    goToProgram(){
      let token = sessionStorage.getItem('userToken')
      console.log(token)
      window.location.href =
            `${process.env.VUE_APP_ORGANIZATION_URL}/redirect?token=${token}&to=admin-workspace-programs`
    },
    goToProgramName(){
      let token = sessionStorage.getItem('userToken')
      if(this.$route.query.from ==='admin-infosession'){
        window.location.href =
            `${process.env.VUE_APP_ORGANIZATION_URL}/redirect?token=${token}
        &to=${this.$route.query.from}&id=${this.$route.query.id}&programTitle=${this.$route.query.program_title}&programId=${this.$route.query.program_id}`
      }
      else{
        window.location.href =
            `${process.env.VUE_APP_ORGANIZATION_URL}/redirect?token=${token}
        &to=${this.$route.query.from}&id=${this.$route.query.program_id}&programTitle=${this.$route.query.program_title}&view=applicants`

      }
       },
    getProfile(){
      if(this.$route.query.jobId){
        this.loader = true
        getUserProfile(getSiteName(), this.$route.query.jobId, this.$route.query.email).then(res => {
          this.candidateDetails = res.data
          console.log(res.data)
          if(res.data.interview_ids.length){
            this.getInterviewDetails(res.data.interview_ids[res.data.interview_ids.length-1])
          }
        })
            .catch(error => {
              console.log(error)
            })
            .finally(() => this.loader = false)
      }
    },
    startInterview() {
      this.startModal = true
    },
    closePaymentModal(){
      this.paymentModal = false
    },
    inviteForInterview() {
      this.$router.push(
          {
            name: 'InterviewScheduleView',
            params: {id:this.$route.params.id,step: '1'},
            query: {...this.$route.query, name: this.$route.query.from ? this.$route.query.name : this.candidateDetails.applicant.full_name}
          })
    },
    viewInterviewDetails() {
      this.dialog = true

    },
    interviewButtonAction() {
      this[this.action]()
    },
    closeDialog() {
      this.dialog = false
    },
    openInvite() {
      this.dialog = false
      this.openInterviewModal = true
    },
    closeInvite() {
      this.openInterviewModal = false
      this.dialog = true
    },
    getInterviewDetails(id) {
      getUserInterviewDetails(id)
          .then(res => {
            this.interviewDetails = res.data
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
    },
    getApplicantReport(){
      this.loading = true
      let result =[]
      let label = []
      let api_call;
      if(this.$route.query.jobId){
        api_call = getCandidateReport(this.$route.query.email, this.$route.query.jobId)

      } else {
        api_call = getCandidateProviderReport(this.$route.query.site_name, this.$route.query.email, this.$route.query.applicant_id)

      }
         api_call .then(res => {
            console.log(res.data, 'report')
            if(res.data.length) {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].category === 'psychometric') {
                  res.data[i].psychometric.forEach(function (e) {
                    result.push(+e.score)
                    label.push(e.name)
                  })
                  this.options.labels = label
                  this.totalPercentile = res.data[i].total_percentile
                  return this.psychometricResult = result
                }
                else if( res.data[i].category === 'personality'){
                  this.personalityResult = res.data[i].personality
                  this.personalityTotalPercentile = res.data[i].total_percentile

                }
              }
            }
          })
          .catch(err =>{
            console.log(err)
          })
          .finally(()=>{this.loading = false})
    },
     decodeQueryParam(p) {
      return decodeURIComponent(p.replace(/\+/g, " "));
    },
    async getProgramInterviewData(){

      const payload = {
        site_name : this.$route.query.site_name,
        email: this.$route.query.email,
        id: this.$route.query.applicant_id
      }
      await getUserProgramInterviewDetails(payload ).then(
          res => {
            this.$store.commit('interview/setInterviewId',res.data[0].interview)
          }
      ).catch(err => {
        console.log('here')
        console.log(err.response)
      })
    },
    goToProgramInterview(){
      // this.$router.push({query:{...this.$route.query,is_recommend:'true'}})

      if  (this.applyStage.includes(this.candidateDetails.status))
        if(this.candidateDetails.status === 'interviewed' || this.candidateDetails.status === 'offered_admission'){
          this.$router.push({
            name:'InterviewPageView',
            query:{...this.$route.query,
              psychometric_score:this.totalPercentile,
              status:this.candidateDetails.status,
              personality_score:this.personalityTotalPercentile,
              interview_id:this.getInterviewId,
              is_recommend:'true'
            }
          })

        }else {
          this.$router.push({
            name:'InterviewPageView',
            query:{...this.$route.query,
              psychometric_score:this.totalPercentile,
              status:this.candidateDetails.status,
              personality_score:this.personalityTotalPercentile,
              interview_id:this.getInterviewId,

            }
          })
        }

    },
    gotoStart(){
      startProgramInterview({
        site_name: this.$route.query.site_name,
        interview: this.getInterviewId
      })
      .then(res=>{
        console.log(res)
      })
      this.$router.push({
        name:'InterviewPageView',
        query:{...this.$route.query,
          psychometric_score:this.totalPercentile,
          status:this.candidateDetails.status,
          personality_score:this.personalityTotalPercentile,
          interview_id:this.getInterviewId,

        }
      })
    },
    async getApplicantProgramStatus(callback){
      if(callback === true){
        this.loader = true
        await getProgramApplicationStatus(this.$route.query.applicant_id, this.$route.query.email)
            .then(res=>{
              console.log(res.data)
              this.candidateDetails.status = res.data.status
              this.loader = false
            })
      }else {
        await getProgramApplicationStatus(this.$route.query.applicant_id, this.$route.query.email)
            .then(res=>{
              console.log(res.data)
              this.candidateDetails.status = res.data.status
            })
      }
    }


  }
}

</script>

<style lang="scss" scoped>
.name{
  font-size: 24px;
  font-weight: bold;
  color: #142E70;
  margin-bottom: 1.2rem;
  text-transform: capitalize;
}
.candidateLoader {}

.profile {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 680px) {
    padding: 2rem 5rem;
  }
  @media (min-width: 1030px) {
    padding: 1rem 10rem;
  }
}

.overallPerformanceCard {
  height: 500px;
  @media(min-width: 780px) {
    max-width: 320px;
    position: absolute;
    right: 10%;
  }
}

.score-card {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.performanceScore {
  display: flex;
  justify-content: space-between;;
}

.score {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
}

.score-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
}

.otherRoutes {
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;

  span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #475661;;
  }
}

.actions {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;

}

.eachRoute {
  cursor: pointer;
  margin: 4px 0 ;

}
.no-pointer{
  cursor: none;
}
.status{
  border-radius: 8px;
  padding: 8px 12px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  letter-spacing: 0.07em;

}
.route{
  font-family: DM Sans;
  cursor: pointer !important;
}
.email{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #475661;
}


</style>
