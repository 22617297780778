<template>
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        max-width="500">
      <template>
        <v-card class="tw-p-6" style="border-radius: 16px">
          <div class="tw-flex tw-justify-between">
            <p class="make-offer">{{$route.query.from?'Offer Admission':'Make Offer'}}</p>
            <v-card-actions class="tw-pt-0 justify-end">
              <XIcon @click="$emit('close')"/>
            </v-card-actions>
          </div>
          <p v-if="$route.query.from" class="offer-content">You are about to offer <strong>{{ candidateDetails.full_name }}</strong> admission to the <strong>{{ $route.query.program_title }}</strong> program in your institution. Proceeding means you have
            conducted all necessary screening and found the candidate satisfactory.</p>
          <p v-else class="offer-content">You are about to offer <strong>{{ candidateDetails.full_name }}</strong> employment
            in your organization. Proceeding means you have conducted all necessary screening and found the candidate satisfactory.<br/>
            <strong>ENTER APPLICANT'S FULLNAME TO PROCEED</strong>
          </p>
          <v-text-field id="textfield" v-model="name" hide-details label="Enter Applicant’s Full Name To Proceed" solo></v-text-field>

          <div class="mt-4">
            <small>Message Subject</small>
            <v-text-field id="textfield2" v-model="subject" hide-details solo class="mt-1"></v-text-field>
          </div>
          <div class="mt-4">
            <small>Message Body</small>
            <div class="editor mt-1">
<!--              <vue-editor v-model="message" :editor-toolbar="customToolbar"/>-->
              <v-textarea no-resize solo v-model="message"></v-textarea>
            </div>
          </div>
          <div v-show="file === ''" class="lg:tw-flex tw-my-4 lg:tw-justify-between">
            <p class="fileUpload">Upload Offer Letter</p>
            <div class="tw-flex">
              <input
                  id="assetsFieldHandle"
                  ref="file"
                  accept=".pdf"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  name="fields[assetsFieldHandle][]"
                  style="display: none"
                  type="file"
                  @change="uploadToCloudinary()"/>
              <label class="d-flex align-center justify-center upload-label flex-column cursor-pointer"
                     for="assetsFieldHandle">
                <span class="drag-drop mx-auto">
                  <div class="d-flex">
                    <p class="choose">Choose from File</p>
                    <p class="span">Upload</p>
                  </div>
                </span>
              </label>
            </div>
          </div>
          <div v-if="file" class="file" style="cursor: pointer">
            <div class="d-flex justify-space-between pt-2 px-3">
              <div class="d-flex px-3">
                <PDFIcon/>
                <div>
                  <span class="pl-2 mt-3">{{ shortenedTitle(file.name) }}</span>
                  <p class="bytes">{{ fileSize(file.size) }}</p>
                </div>
              </div>
              <XIcon id="remove" class="mt-3" @click="remove"/>
            </div>
          </div>
          <div>
            <v-card-actions class="justify-end tw-mt-5">
              <BaseButton button-text="Cancel" outlined @click="$emit('close')"/>
              <BaseButton :disabled="name.toLowerCase() !== getName.toLowerCase()" :loading="loading" :button-text="'Make Offer'" class="tw-ml-3"
                          @click="sendOffer"/>
            </v-card-actions>
          </div>
        </v-card>
      </template>
    </v-dialog>
</template>

<script>

import {XIcon} from "vue-feather-icons";
import BaseButton from "@/components/UIComponents/BaseButton";
import PDFIcon from "@/components/UIComponents/reusablesIcon/PDFIcon";
import {mapGetters} from "vuex";
import {uploadMediaFile} from "@/services/utils/fileUploader";
import {makeOffer, offerProgramAdmission} from "@/services/api/APIService";
export default {
  name: "MakeOfferModal",
  components: {PDFIcon, XIcon, BaseButton},
  data() {
    return {
      image: null,
      loading: false,
      candidateOffer: {},
      file: '',
      name: '',
      message: '',
      subject:'',
      status: {},
      assignDialog:false,
      company_logo:{},
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          {align: ""},
          {align: "center"},
          {align: "right"},
          {align: "justify"}
        ],
        [
          {list: "ordered"},
          {list: "bullet"},
          "link"
        ],
        [{size: ["small", false, "large", "huge"]}]
      ],

    }
  },

  props: {
    job_id: String,
    program_id:String,
    dialog: [Boolean],
    candidateDetails: {
      type:Object,
      default(){
        return{
          full_name:'Lara Eze'

        }
      }
    },
  },

  computed: {
    ...mapGetters(['getMakeOffer']),

    getName() {
      return this.candidateDetails.full_name
    }
  },

  methods: {
    closeAssignModal(){
      this.file = ''
      this.name = ''
      this.message = ''
      this.assignDialog = false
    },
    async sendOffer() {
      this.loading = true

      try {
        if (this.file){
          await this.handleImage(this.file)

        }

        let api_call
        if (this.$route.query.from) {
          let data = {
            site_name: this.$route.query.site_name,
            application: this.$route.query.applicant_id,
            candidate_name: this.$route.query.name,
            email: this.$route.query.email,
            message: this.message,
            message_subject: this.subject,
            offer_letter: this.company_logo.company_logo_url,
            file_name: this.file.name,
            file_size: this.file.size

          }
          console.log('if',data)
          api_call = offerProgramAdmission(data)
        }
        else {
          const data = {
            job: this.job_id,
            candidate_email: this.$route.query.email,
            candidate_name: this.name,
            message: this.message,
            message_subject: this.subject,
            offer_letter: this.company_logo.company_logo_url,
            file_name: this.file.name,
            file_size: this.file.size
          }
          console.log('else',data)

          // api_call = await this.$store.dispatch('makeOffer', data)
          api_call = makeOffer(data)
        }
        api_call.then(res => {
          console.log(res)
          if (this.$route.query.from) {
            // this.assignDialog = true
            this.$emit('close')
            this.$emit('getProfile')
          }
          else {
            this.file = ''
            this.name = ''
            this.message = ''
            this.subject = ''
            this.$emit('getProfile')

            this.$emit('close')
          }
          this.$displaySnackbar({
            message: 'Offer Sent to Candidate!',
            success: true
          })
        })
            .catch(error => {
              console.log(error)
              this.$displaySnackbar({
                message: error.data.detail,
                success: false
              })
            })
            .finally(() => this.loading = false)
      }
      catch (e) {
        console.log(e)
        this.loading = false

      }
    },

    reload(){
       window.locations.reload()
    },

    async uploadToCloudinary() {
      // this.loading = true
      this.file = this.$refs.file.files[0];
      // console.log(this.file, 'filess')
      const exts = ['pdf']
      // const exts = ['png', 'jpeg', 'jpg', 'tiff']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension)) {
        this.file = "";
        return
      }
    },

    async handleImage(file) {
      const { key: company_logo_url, name: company_logo, size: company_logo_file_size } = await uploadMediaFile(file, 'offer')
      this.company_logo = {company_logo_url, company_logo, company_logo_file_size}
      console.log(this.company_logo);
    },

    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 40)
    },

    fileSize(size) {
      if (!size) return ''
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    },

    onChange() {
      this.file = this.$refs.file.files[0];
      this.showDragDiv = false;
    },

    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },

    dragleave() {
      this.color = "#FBFDFE";
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      this.color = "#FBFDFE";
    },

    remove() {
      this.file = "";
      this.showDragDiv = true;
    },
  }
}
</script>

<style scoped lang="scss">
.eachRoute {
  cursor: pointer;
}

span {
  font-family: DM Sans, serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;;
}

.make-offer {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}

.offer-content {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
  margin-bottom: 30px;
  text-transform: none;
}

.fileUpload {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;
  margin-top: 15px;
}

.choose {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.file {
  margin-bottom: 15px;
  margin-top: 15px;
}

.span {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 12px;
  color: #008EEF;
  background: #F7FCFF;
  border-radius: 4px;
  font-family: DM Sans, serif;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}
.otherRoutes {
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;

  span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #475661;;
  }
}

.bytes {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #9CABB5;
  margin-left: 8px;
}
</style>
