<template>
<div>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile" v-model="openModal" max-width="600px" persistent>
    <v-card width="600px" class="card scroll">
      <div>
        <div class="tw-flex tw-p-6 tw-justify-end">
          <div class="tw-cursor-pointer" @click="$emit('close')">
            <vue-feather type="x" style="width:19px"/>
          </div>
        </div>

        <div>
          <v-img
              :src="image"
              class="round-image pr-2"
              transition="fade"
              contain
              :loading="loading"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import paymentProof from "@/mixins/paymentProof";

export default {
  name: "PaymentProofModal",
  mixins:[paymentProof],
  props:{
    openModal: [Boolean],
    image: String
  },
  computed: {
    ...mapGetters(['getPaymentDetails'])
  },
  data(){
    return{
      loading: false,
      // paymentDetails:{}
    }
  },
  mounted() {
    console.log(this.getPaymentDetails)
  }
}
</script>

<style scoped>
.card{
  height: 492px;
  background: #FFFFFF;
  border-radius: 12px;
}
.scroll {
  overflow-y: auto !important;
  overflow-x: hidden;
  max-height: calc(100vh - 150px) !important;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 10px !important;
  border-radius: 6px;
}
</style>