<template>
  <div class="details-summary">
    <div class="candidateProfileBackRoute">
      <span class="tw-mr-1 route" @click="goToJob">Jobs</span>/<span class="tw-mr-1 tw-ml-1 route" style="white-space: nowrap" @click="goToJobRoleTable">{{ details.job_name }}</span>/<span class="tw-ml-1 tw-text-enumBlue route">Candidate</span>
    </div>
    <div class="selected-candidateName">
      <span class="tw-text-enumDarkBlue mr-2 name">{{details.applicant.full_name}}</span>
    </div>
    <div class="tagDiv">
      <Tags :tag="'age,'+ ' ' +  details.applicant.age"/>
      <Tags :tag="details.applicant.gender"/>
      <Tags :tag="details.applicant.location"/>
<!--      <Tags :tag="experience"/>-->
    </div>






  </div>

</template>

<script>
import Tags from "@/components/candidateProfile/Tags";
export default {
name: "summaryOfCandidateDetails",
  components: {Tags},
  data: ()=>{
  return{
    firstName: "",
    lastName: "",
    age: "age, 24",
    gender: "Male",
    location: "Lagos,Nigeria",
    experience: "2 years of Experience"
  }
},
  props: {
    details: Object,
  },
  methods:{
    goToJobRoleTable(){
      // http://172.16.0.116:8080/dashboard/jobs/candidates/196
      this.$router.push({name:'AllCandidates',params:{id:this.$route.query.jobId}})
    },
    goToJob(){
      this.$router.push({name:'JobSummaryOverview'})
    }
  }

}
</script>

<style scoped lang="scss">
.details-summary{
  border-bottom: 1px solid #D0DCE4;
  @media (min-width: 680px) {
    border: none;
  }
}
.tagDiv{
  font-family: DM Sans;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.candidateProfileBackRoute{
  font-family: DM Sans;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0.8rem;

}
.name{
  font-size: 24px;
  font-weight: bold;
  color: #142E70;
  margin-bottom: 1.2rem;
  text-transform: capitalize;
}
.selected-candidateName{
  margin-bottom: 1rem;
}
.route{
  font-family: DM Sans;
}

</style>
