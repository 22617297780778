<template>
  <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 13579">
      <g id="file">
        <path id="Vector" d="M23.293 4.19824H10.7513C9.80094 4.19824 8.88951 4.57577 8.2175 5.24778C7.5455 5.91978 7.16797 6.83122 7.16797 7.78158V36.4482C7.16797 37.3986 7.5455 38.31 8.2175 38.982C8.88951 39.654 9.80094 40.0316 10.7513 40.0316H32.2513C33.2017 40.0316 34.1131 39.654 34.7851 38.982C35.4571 38.31 35.8346 37.3986 35.8346 36.4482V16.7399L23.293 4.19824Z" fill="#CA1520"/>
        <path id="Vector_2" d="M23.293 4.19824V16.7399H35.8346" fill="#FA8383"/>
      </g>
      <path id="PDF" d="M14.9997 26.7402V21.1402H16.9597C17.3971 21.1402 17.7597 21.2149 18.0477 21.3642C18.3357 21.5082 18.5491 21.7082 18.6877 21.9642C18.8317 22.2149 18.9037 22.5002 18.9037 22.8202C18.9037 23.1242 18.8344 23.4042 18.6957 23.6602C18.5624 23.9109 18.3517 24.1109 18.0637 24.2602C17.7757 24.4096 17.4077 24.4842 16.9597 24.4842H15.8477V26.7402H14.9997ZM15.8477 23.7882H16.9197C17.3197 23.7882 17.6051 23.7029 17.7757 23.5322C17.9517 23.3562 18.0397 23.1189 18.0397 22.8202C18.0397 22.5109 17.9517 22.2709 17.7757 22.1002C17.6051 21.9242 17.3197 21.8362 16.9197 21.8362H15.8477V23.7882ZM19.8219 26.7402V21.1402H21.6379C22.2939 21.1402 22.8326 21.2549 23.2539 21.4842C23.6806 21.7136 23.9952 22.0389 24.1979 22.4602C24.4059 22.8762 24.5099 23.3722 24.5099 23.9482C24.5099 24.5189 24.4059 25.0149 24.1979 25.4362C23.9952 25.8522 23.6806 26.1749 23.2539 26.4042C22.8326 26.6282 22.2939 26.7402 21.6379 26.7402H19.8219ZM20.6699 26.0202H21.6059C22.1126 26.0202 22.5126 25.9376 22.8059 25.7722C23.1046 25.6069 23.3179 25.3696 23.4459 25.0602C23.5792 24.7509 23.6459 24.3802 23.6459 23.9482C23.6459 23.5162 23.5792 23.1429 23.4459 22.8282C23.3179 22.5136 23.1046 22.2736 22.8059 22.1082C22.5126 21.9376 22.1126 21.8522 21.6059 21.8522H20.6699V26.0202ZM25.5269 26.7402V21.1402H29.0389V21.8282H26.3749V23.5962H28.5829V24.2762H26.3749V26.7402H25.5269Z" fill="white"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PDFIcon"
}
</script>

<style scoped>

</style>