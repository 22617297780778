<template>
  <v-dialog
      transition="dialog-top-transition"
      width="450"
      v-model="startModal"
      persistent
  >
    <template>
      <v-card class="card">
        <div class="tw-flex title tw-items-center tw-justify-between">
          <h3 class="tit">{{!checkDate?'Interview can’t be started': checkIsInvited}}</h3>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="tw-my-6" v-if="!checkDate">
          <h4 class="card-text">This interview is scheduled for <br>
            <span class="font-weight-bold">{{date}} - {{time}}</span></h4>
        </div>
        <div class="tw-my-8" v-else-if="is_invited&& checkDate && status!=='Interviewing'">
          <h4 class="card-text md:tw-w-80">This interview is yet to start,Contact the admin that scheduled this interview to start</h4>

        </div>
        <div class="tw-my-8" v-else>
          <h4 class="card-text md:tw-w-80">You are about to {{is_invited?'join':'start'}} an interview session with a candidate. Do you wish to Proceed?</h4>

          <div class="tw-flex tw-mt-4">
            <v-spacer/>
            <BaseButton :outlined="true" @click="$emit('close')" class="tw-mr-2" width="75px" height="48px" button-text="No"/>
            <BaseButton :loading="loading" :depressed="true" @click="startInterview" width="75px" height="48px" button-text="Yes"/>

          </div>
        </div>

      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import BaseButton from "@/components/UIComponents/BaseButton";
import {startInterview} from "@/services/api/APIService";
export default {
name: "StartJoinInterviewModal",
  components: {BaseButton},
  props:{
    startModal:[Boolean],
    detail:{},
    is_invited:[Boolean],
    status:[String]
  },
  data(){
  return{
    time:'',
    date:'',
    loading:false
  }
  },
  methods:{
  startInterview(){
    if(!this.is_invited) {
      let data = {
        interview_id: this.detail.id,
        applicant_email: this.$route.query.email
      }
      startInterview(data)
          .then(res => {
            console.log(res.data)
            this.conductInterview()
          })
          .catch(err => {
            console.log(err)
          }).finally(() => {
        this.loading = false
      })
    }
    else {
      this.conductInterview()
    }
  },
    conductInterview(){
      this.$emit('close')
      this.$router.push({
        name:'ConductInterviewView',
        query:{
          email:this.$route.query.email,
          jobId:this.$route.query.jobId,
          interview:this.detail.id,
          id:this.$route.params.id,
          isInvited:this.is_invited
        }
      })
    },
  },

  computed:{
  checkIsInvited(){
    let text
    if(this.is_invited){
      text = 'Join Interview Session'
    }
    else {
      text = 'Start Interview'
    }
    return text
  },
    checkDate(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.time = moment(this.detail.time, "HH:mm:ss").format("hh:mm A");
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.date =  moment(this.detail.date).format('dddd, MMMM Do YYYY');
      return moment(this.detail.date).isSame(Date.now(), 'day')
    }
  }
}
</script>

<style scoped>
.card{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
  padding: 15px;


}
.tit{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #001343;

}
.card-text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #1E323F;

}


</style>
