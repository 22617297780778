<template>
  <div>
    <template>
      <v-dialog :fullscreen="$vuetify.breakpoint.mobile" v-model="open" max-width="408px" persistent>
        <v-skeleton-loader v-if="pageLoading" type="card"/>
        <v-card v-else width="408px" class="card">
          <div class="tw-p-6">
            <div class=" tw-flex tw-mb-6 tw-justify-between">
              <div class="Title pb-2">
                <h2>Payment Details</h2>
              </div>
              <div class="tw-cursor-pointer" @click="$emit('close')">
                <vue-feather type="x" style="width:19px"/>
              </div>
            </div>

            <div v-if="status==='receipt_uploaded'" class="mb-n4">
              <div>
                <h5>Payment Mode</h5>
              </div>
              <div class="tw-my-4">
                <p>{{getPaymentDetails.payment_mode}}</p>
              </div>
              <div>
                <h5>Date</h5>
              </div>
              <div class="tw-my-4">
                <p>{{ getPaymentDetails.payment_date }}</p>
              </div>
              <div>
                <h5>Amount</h5>
              </div>
              <div class="tw-my-4">
                <p>{{getPaymentDetails.amount}}</p>
              </div>
              <div>
                <h5>Name of Depositor</h5>
              </div>
              <div class="tw-my-4">
                <p>{{getPaymentDetails.depositor}}</p>
              </div>
              <div>
                <h5>Account Name</h5>
              </div>
              <div class="tw-my-4">
                <p>{{getPaymentDetails.account_name}}</p>
              </div>
              <div>
                <h5>Bank Name</h5>
              </div>
              <div class="tw-my-4">
                <p>{{getPaymentDetails.bank_name}}</p>
              </div>
              <div>
                <h5>Payment Receipt</h5>
              </div>
              <div class="tw-mt-2 box" v-if="getPaymentDetails.payment_proof">
                <v-img
                    :src="getPaymentDetails.payment_proof"
                    class="round-image pr-2"
                    transition="fade"
                    contain
                    @click="proof=true"

                />
              </div>
              <div class="tw-mb-5 tw-mt-3 tw-mx-4 md:tw-flex md:tw-justify-end">
                <BaseButton  outlined style="border-radius: 8px !important;" :block="true"
                            button-text="Not Confirmed" @click="reject" class="md:tw-w-36"/>
                <BaseButton  :loading="loading" style="border-radius: 8px !important;" :block="true" depressed @click="confirm"
                            button-text="Confirm"   class="md:tw-ml-4 tw-mt-3 md:tw-mt-0"/>
              </div>
            </div>

            <div v-else>
            <div class="mb-n4">
              <div class="tw-flex">
                <h5>Payment Mode</h5>
                <span style="color: #FF2E2E">*</span>
              </div>
              <v-select
                  id="paymentMode"
                  placeholder="Payment Mode"
                  :items="mode"
                  v-model="payment.payment_mode"
                  :menu-props="{ bottom: true, offsetY: true }"
                  solo
                  item-text="path"
                  item-value="value"
              />
            </div>

            <div class="tw-mb-3.5">
              <div class="tw-flex">
                <h5>Enter Date</h5>
                <span style="color: #FF2E2E">*</span>
              </div>
              <v-menu
                  v-model="showDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="payment.payment_date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      @click:append="on.click"
                      solo
                      placeholder='Ex. 23-12-2022'
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="payment.payment_date"
                    @input="showDate = false"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="tw-pb-3.5">
              <div class="tw-flex">
                <h5>Enter Amount</h5>
                <span style="color: #FF2E2E">*</span>
              </div>
              <ValidationProvider
                  name="amount"
                  v-slot="{ errors }"
                  rules="numeric"
              >
              <v-text-field
                  id="amount"
                  v-model="payment.amount"
                  solo
                  type="number"
                  hide-details
              />
                <span v-show="errors" class="err">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="tw-pb-3.5">
              <div class="tw-flex">
                <h5>Name of Depositor</h5>
                <span style="color: #FF2E2E">*</span>
              </div>
              <v-text-field
                  v-model="payment.depositor"
                  solo
                  hide-details/>
            </div>

            <div class="tw-pb-3.5">
              <div class="tw-flex">
                <h5>Enter Account Name</h5>
                <span style="color: #FF2E2E">*</span>
              </div>
              <v-text-field
                  v-model="payment.account_name"
                  solo
                  hide-details/>
            </div>

            <div class="tw-pb-3.5">
              <div class="tw-flex">
                <h5>Enter Bank Name</h5>
                <span style="color: #FF2E2E">*</span>
              </div>
              <v-text-field
                  v-model="payment.bank_name"
                  solo
                  hide-details/>
            </div>

            <div class="tw-pb-3.5">
              <div class="tw-flex">
              <h5>Upload Payment Proof</h5>
              <span style="color: #FF2E2E">*</span>
                </div>
              <v-card :loading="loading" flat v-model="showDragDiv">
                <div
                    v-show="file === ''"
                    :style="{ 'background-color': color }"
                    class="drag-div tw-bg-enumBlue5"
                    @dragleave="dragleave"
                    @dragover="dragover"
                    @drop="drop"
                    id="drop"
                >
                  <input
                      id="assetsFieldHandle"
                      ref="file"
                      accept="image/*"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      name="fields[assetsFieldHandle][]"
                      style="display: none"
                      type="file"
                      @change="uploadToCloudinary()"

                  />
                  <label class="d-flex px-1 pt-3 align-center justify-center upload-label flex-column cursor-pointer"
                         for="assetsFieldHandle">
                    <vue-feather type="upload"/>
                    <span class="drag-drop mx-auto">
                    <p class="font-weight-bold tw-text-enumGrey2 my-2">Drag and drop or <span class="choose__file">choose image</span></p>
                    <p class="size__instruction tw-text-enumGrey3 d-block ">max size 1MB</p>
                  </span>
                  </label>
                </div>
              </v-card>
              <div v-if="!payment.payment_proof && loading" class="file" style="cursor: pointer">
                <div class="d-flex align-center justify-center pt-3 px-3">
                  <span>Loading...</span>
                </div>
              </div>
              <div v-if="payment.payment_proof && !loading" style="cursor: pointer">
                <div class="tw-flex align-center">
                  <div class="tw-flex align-center">
                    <v-img
                        :src="payment.payment_proof"
                        class="round-image pr-2"
                        transition="fade"
                        height="118"
                        width="150"
                        contain

                    />
                    <div class="tw-flex tw-flex-col">
                      <span class="tw-pl-3" v-if="file.name">{{ shortenedTitle(file.name) }}</span>
                      <span class="image__actions d-flex align-end " :class="{ 'bg-transparent': payment.payment_proof }">
                        <span class="tw-flex lg:tw-flex-row tw-flex-col  pl-3" >
                          <v-btn
                              class="mr-3 tw-mb-5 lg:tw-mb-0"
                              depressed
                              @click.prevent="openExplorer"
                          >
                            Replace
                          </v-btn>
                        </span>
                      </span>

                    </div>

                  </div>
                </div>
              </div>

            </div>
              <div class="tw-mb-5 tw-mt-3 tw-mx-4 md:tw-flex md:tw-justify-end">
                <BaseButton id="notConfirmed" outlined style="border-radius: 8px !important;" :block="true"
                            button-text="Not Confirmed" @click="reject" class="md:tw-w-36"/>
                <BaseButton id="confirmed" :loading="loading" style="border-radius: 8px !important;" :block="true" depressed @click="confirm"
                            button-text="Confirm" :disabled="!payment.depositor||!payment.bank_name||!payment.account_name||!payment.amount||!payment.payment_date ||!payment.payment_mode ||!payment.payment_proof "  class="md:tw-ml-4 tw-mt-3 md:tw-mt-0"/>
              </div>
            </div>
          </div>


          <PaymentProofModal :openModal="proof" :image="getPaymentDetails.payment_proof" @close="proof=false"/>

        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {viewFile} from "@/services/storage/aws";
import {uploadMediaFile} from "@/services/utils/fileUploader";
import {mapGetters} from "vuex";
import {confirmPaymentDetails,rejectPayment} from "@/services/api/APIService";
import PaymentProofModal from "@/components/candidateProfile/PaymentProofModal";


export default {
  name: "ViewPaymentModal",
  components: {PaymentProofModal, BaseButton},
  props: {
    open: [Boolean],
    status:[String],
    id:[Number, Boolean]
  },
  computed: {
    ...mapGetters(['getPaymentDetails'])
  },
  data() {
    return {
      showDate: false,
      img: '',
      loading: false,
      showDragDiv: true,
      pay:{},
      color: "",
      pageLoading: false,
      payment_date: null,
      payment_proof: "",
      payment_proof_url: "",
      paymentDetails: {},
      mode: [
        {
          path: 'Bank Transfer',
          value: 'bank_transfer'
        },
        {
          path: 'USSD',
          value: 'ussd'
        },
        {
          path: 'Bank Deposit',
          value: 'bank_deposit'
        }
      ],
      file: "",
      proof: false,
      imageInfo: '',
      image_url: '',
      payment: {
        depositor: '',
        payment_date: '',
        payment_mode: '',
        bank_name: '',
        amount: '',
        account_name: '',
        payment_proof: '',
      },

    }
  },

  watch: {
    getPaymentDetails: {
      async handler(val) {
        if (val) {
          await viewFile({url: val.payment_proof, context: val, property: 'payment_proof'})
        }
      },
      immediate: true
    },
    open:{
      async handler(val){
        if(val){
          let data={
            site_name: this.$route.query.site_name,
            applicant_id: this.$route.query.applicant_id
          }
          this.pageLoading = true
          await this.$store.dispatch('getPaymentDetails', data)
          this.pageLoading = false


        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    async confirm() {
      this.loading=true
      let data = {
        detail: this.getPaymentDetails.id,
        site_name: this.$route.query.site_name,
        applicant_id: +this.$route.query.applicant_id
      }
      console.log(data.detail)
      if (this.getPaymentDetails.id) {
        await confirmPaymentDetails(data)
            .then(res => {
              this.$displaySnackbar({
                message: res.data.detail,
                success: true
              })
            })
            .catch(err => {
              console.log(err)
            }).finally()
        this.loading=false
        this.$emit('close')
      }
      else {
        this.loading =true
        let payload = {
          site_name: this.$route.query.site_name,
          applicant_id: +this.$route.query.applicant_id,
          detail: ''
        }
        console.log(payload, this.payment)
        await confirmPaymentDetails(payload, this.payment)
            .then(res => {
              this.$displaySnackbar({
                message: res.data.detail,
                success: true
              })
            })
            .catch(err => {
              console.log(err)
            }).finally()
        this.loading = false
        this.$emit('close'),
            console.log(data)
      }

    },

    reject() {
      this.loading = true
      let data = {
        site_name: this.$route.query.site_name,
        applicant_id: this.$route.query.applicant_id,
        detail: this.getPaymentDetails.id
      }
      console.log(data)
      rejectPayment(data)
          .then(res => {
            console.log(res)
            this.$displaySnackbar({
              message: res.data.detail,
              success: true
            })
          })
          .catch(e => {

            console.log(e)
          })
          .finally(() =>
                  this.$emit('close'),
              this.loading = false)
    },

    openExplorer() {
      this.$refs.file.click();
    },
    getSize(size) {
      if (!size) return "";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
          (Math.ceil(size / Math.pow(1024, i)).toFixed(2) * 1) +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
    async handleImage(file) {
      const {
        key: payment_proof_url,
        name: payment_proof,
        size: payment_proof_file_size
      } = await uploadMediaFile(file, 'payment_proof')
      let paymentProof = {payment_proof_url, payment_proof, payment_proof_file_size};
      this.payment_proof_url = paymentProof.payment_proof_url
      viewFile({ url: payment_proof_url, context: this, property: 'paymentProofUrl' })
      console.log(this.payment_proof_url,'hiii')
      const fr = new FileReader()
      fr.onload = ({target: {result}}) => {
        this.payment.payment_proof = result
      }
      fr.readAsDataURL(file)
    },
    async uploadToCloudinary() {
      this.loading = true
      this.file = this.$refs.file.files[0];
      console.log(this.file)
      const exts = ['png', 'tiff', 'jpg', 'jpeg']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      console.log(fileExtension)
      if (!exts.includes(fileExtension)) {
        this.$displaySnackbar({message: 'Only accepts Image files', success: false})
        this.loading = false
        this.file = "";
        return
      }
      let size = this.getSize(this.file.size)
      if (size.split(' ')[1] === 'MB') {
        this.$displaySnackbar({message: 'Maximum Image size is 1MB', success: false})
        this.file = "";
        this.loading = false
        this.showDragDiv = false;
        return
      }
      try {

        await this.handleImage(this.file);
        this.loading = false
      } catch (e) {
        this.loading = false;

      }
    },

    onChange() {
      this.file = this.$refs.file.files[0];
      // await this.uploadToCloudinary()
      this.showDragDiv = false;
    },
    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 20)
    },
    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },
    dragleave() {
      this.color = "#FBFDFE";
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      this.uploadToCloudinary()
      this.color = "#FBFDFE";
    },
    remove() {
      this.file = "";
      this.showDragDiv = true;
    },

  }
}
</script>

<style scoped lang="scss">
.Title{
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.card{
  background: #FFFFFF;
  border-radius: 13px;
}
.file {
  border-radius: 8px;
  width: 280px;
  height: 50px;
  border: 1px solid #eaeaea;
  margin-bottom: 28px;
}
h5{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.drag-div {
  border: 1px dashed #008EEF;
  box-sizing: border-box;
  border-radius: 10px;
  height: 118px;
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
.choose__file {
  color: #008EEF;
  font-family: "DM Sans",serif;
}
.size__instruction {
  font-size: 11px;
}
p{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1E323F;
}
.box{
  background: #F7FCFF;
  border: 1px dashed #008EEF;
  border-radius: 8px;
}
</style>