<template>

  <v-dialog v-model="assignDialog" max-width="485" >
    <div class='assignModal tw-p-6'>

      <div class='tw-flex justify-space-between'>
       <h4>Add to a Cohort</h4>
        <v-btn icon @click="$emit('close-submit')">
          <vue-feather type="x" class='tw-pt-1' @click='$emit( `close`)'/>
        </v-btn>
      </div>

      <h5 class='tw-mt-7 tw-mb-6'>Select a Cohort</h5>
      <div v-if="cohorts.length">
        <div v-for="(data,i) in cohorts" :key="i">
          <div @click="selectCohort(data.id)" class=' tw-mb-3 tw-w-full' :class="[selectedCohort === data.id ?'cohortCard':'cohortCard2']">

            <div class='tw-flex'>
              <div class='tw-mt-2.5 tw-ml-4' >
                <v-avatar tile size='59'>
                  <img :src='data.image'/>
                </v-avatar>
              </div>
              <div class='tw-pl-5'>
                <h6 class='tw-mt-2'>{{data.name}}</h6>
                <span class='tw-mb-1'>{{data.learner_count}} {{data.learner_count>1?'learners':'learner'}}</span>
              </div>

              <v-spacer></v-spacer>

              <div class='tw-mt-6 tw-pr-4' v-if="selectedCohort === data.id">
                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.9376" cy="13.0001" r="12.0626" fill="#008EEF"/>
                  <path d="M16.9375 10L11.4375 15.5L8.9375 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
            </div>

          </div>
        </div>

      </div>
      <div v-else>
        <EmptyStateComponent>
          <template #empty-text>
            No cohort available
          </template>
        </EmptyStateComponent>
      </div>
      <div class='tw-flex justify-end align-end tw-mt-7'>
        <BaseButton class='tw-pr-3' outlined width='103' button-text='Cancel' @click="$emit('close')"/>
<!--        <BaseButton :disabled="!selectedCohort" :loading="loading" width='133' @click="assignToCohort" button-text='Add'/>-->
        <BaseButton :disabled="!selectedCohort" :loading="loading" width='133' @click="openNextModal" button-text='Add'/>
      </div>

    </div>
    <AddToACohort :id="selectedCohort" :addToACohort="cohortAdd" @close="closeAddModal"/>
  </v-dialog>

</template>

<script>
import BaseButton from '@/components/UIComponents/BaseButton'
import {getAllCohort} from "@/services/api/APIService";
import {viewFile} from "@/services/storage/aws";
import EmptyStateComponent from "@/components/reusables/EmptyStateComponent";
import AddToACohort from "@/components/candidateProfile/AddToACohort";
export default {
  name: 'AssignApplicantToCohortModal',
  components: {AddToACohort, EmptyStateComponent, BaseButton },
  props: {
    assignDialog: {Boolean},
  },
  data () {
    return {
      bell: require('@/assets/users.svg'),
      ellipse: require('@/assets/check.svg'),
      selectedCohort:'',
      cohortAdd: false,
      // dialogAssign: this.assignDialog,
      cohorts:[

      ],
      loading:false
    }
  },
  watch: {
    cohorts: {
      handler: function () {
       this.cohorts.forEach(obj => viewFile({ url: obj.image, context: obj }))

        // viewFile({url: data.image, context: this})
      },
      deep: true,
      immediate:true
    }
  },
  created() {
    this.getAllCohortInAProgram()
  },
  methods:{
    closeAddModal(){
      this.cohortAdd = false
      this.$emit('close')
    },

    openNextModal() {
      this.cohortAdd = true
      this.$emit('close')
    },

    selectCohort(data){
     return  this.selectedCohort = data
    },
    getAllCohortInAProgram(){
      if(this.$route.query.site_name){
        getAllCohort(this.$route.query.site_name, this.$route.query.program_id)
            .then(res => {
              console.log(res.data)
              this.cohorts = res.data
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
              this.loading = false
            })
      }

    },
    goToProgramName(){
      let token = sessionStorage.getItem('userToken')
      window.location.href =
          `${process.env.VUE_APP_ORGANIZATION_URL}/redirect?token=${token}
        &to=${this.$route.query.from}&id=${this.$route.query.program_id}&programTitle=${this.$route.query.program_title}&view=applicants`
    },
  }

}
</script>

<style scoped>

.assignModal {
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}

h4 {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1E323F;
}

h5 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #1E323F;
}

.cohortCard {
  background: #FFFFFF;
  border: 2px solid #008EEF;
  border-radius: 12px;
  height: 78px;
}

.cohortCard2 {
  background: #FFFFFF;
  height: 78px;
  border: 1px solid #D0DCE4;
  border-radius: 12px;
}
.cohortCard2:hover{
  background: #F7FCFF;
  border: 2px solid #008EEF;
  box-sizing: border-box;
  cursor: pointer;

}

h6 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #1E323F;}

span {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
  }




</style>
