<template>
    <div>
      <div class="tw-flex">
        <v-avatar id="image" color="#ECF4F7" size="30">
<!--          <span class="initials">{{ admin && Initials(admin.full_name) }}</span>-->
          <span class="initials">{{Initials(details.admin_name)}}</span>
        </v-avatar>
<!--        <p class="name tw-ml-3">{{ admin.full_name }}</p>-->
        <p class="name tw-ml-2 tw-mt-1 tw-pb-2">{{details.admin_name}}</p>
      </div>
      <div class="tw-flex tw-mb-3 tw-pb-4">
        <div class="tw-flex tw-mr-3">
          <CalendarIcon style="width:20px !important" />
          <span class="content tw-ml-2">{{formatDate(details.date_created)}}</span>
        </div>
        <div class="tw-flex">
          <ClockIcon  style="width:20px !important"/>
          <span class="content tw-ml-2">{{formatTime(details.date_created)}}</span>
        </div>
      </div>
        <v-divider></v-divider>
        <div class="tw-mt-4">
          <h3 class="sub-text">Primary Concern</h3>
          <p class="content">{{details.reason}}</p>
        </div>
        <div>
          <h3 class="sub-text">Details</h3>
          <p class="content">{{details.message}}</p>
        </div>
    </div>
</template>

<script>

import {CalendarIcon, ClockIcon} from "vue-feather-icons";
import {getRejectionDetails} from "@/services/api/APIService";
import moment from "moment";

export default {
  name: "RejectionDetails",
  components: {CalendarIcon, ClockIcon},
  data() {
    return {
      dialog: false,
      details:{}
     }
  },

  props: {
    job_id: String,
    candidateDetails: Object
  },
  mounted() {
    this.rejectionDetails()
  },
  computed: {
    formatDate() {
      return function (date) {
        return moment(date).format('Do MMM, YYYY');

      }
    },
    formatTime() {
      return function (time) {
        return moment(time.split("T")[1], "HH:mm:ss").format("hh:mm A");

      }
    },
  },


  methods: {
    Initials(item) {
      if(item) {
        const fullName = item.split(' ');
        const initials = fullName && fullName.shift().charAt(0) + fullName.shift().charAt(0);
        return initials.toUpperCase();
      }
    },
    rejectionDetails(){
      getRejectionDetails(this.$route.query.jobId,this.$route.query.email)
      .then(res=>{
        console.log(res)
        this.details = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    }
  }

}
</script>

<style scoped>
.name {
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #1E323F;
}

.initials {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #008EEF;
}

.sub-text {
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #475661;
}

.content {
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #475661;
}
</style>
