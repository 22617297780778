<template>
  <div class="py-1 px-2 tag" @click="$emit('click')">
    <h4>{{ tag }}</h4>
  </div>
</template>

<script>
export default {
name: "Tags",
  props: {
  tag: String
  }
}
</script>

<style scoped>
.tag{
  border: 1px solid #E0E9FF;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  text-transform: capitalize;
  color: #1E323F;
  margin-bottom: 12px;

}
h4{
  font-family: DM Sans;
}
</style>