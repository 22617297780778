
<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px">
      <template v-slot:default="dialog">
        <v-card style="border-radius: 16px" class="tw-p-6">
          <div class="tw-flex tw-justify-between">
            <p class="reject-candidate">Reject Candidate</p>
            <v-card-actions class="tw-pt-0 justify-end">
              <XIcon @click="$emit('close')"/>
            </v-card-actions>
          </div>
          <p class="offer-content">
            You are about to reject <strong>{{candidateDetails.full_name}}</strong>. We would send a constructive feedback to candidate based on the reasons selected for rejection.
          </p>
          <v-combobox v-model="reason" :items="reasons" id="combo" hide-details solo label="Select a Reason"></v-combobox>
          <div class="tw-mt-5">
            <small>Custom Message (Optional)</small>
            <v-textarea v-model="message" outlined hide-details></v-textarea>
          </div>
          <div class="lg:tw-flex lg:tw-justify-between">
            <div class="tw-flex tw-mt-4">
              <v-checkbox
                  v-model="move_to_talent_pool"
                  color="#8F47F4"
                  value="Move to Talent Pool"
                  hide-details
              ></v-checkbox>
              <span class="tw-mt-5">Move to Talent Pool</span>
            </div>
            <v-card-actions class="justify-end tw-mt-5">
              <BaseButton @click="dialog.value = false" outlined button-text="Cancel"/>
              <BaseButton button-text="Reject" :disabled="!reason" @click="rejectCandidate" class="tw-ml-3"/>
                  </v-card-actions>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>

import {XIcon} from "vue-feather-icons";
import BaseButton from "@/components/UIComponents/BaseButton";
import {rejectOffer} from "@/services/api/APIService";

export default {
  name: "RejectCandidateModal",
  components: { XIcon, BaseButton},
  // RemoveCandidateModal,
  data() {
    return {
      message: '',
      reason: '',
      reasons: ['Not enough experience', 'Location'],
      move_to_talent_pool : false,
    }
  },

  props: {
    job_id: String,
    candidateDetails: Object,
    dialog: [Boolean],

  },

  methods: {
    closeDialog() {
      this.dialog = false
    },

    check() {
      if (this.move_to_talent_pool){
        return true
      }
      else return false
    },

    rejectCandidate() {
      const data = {
        job: this.job_id,
        candidate: this.candidateDetails.email,
        reason: this.reason,
        message: this.message,
        move_to_talent_pool: this.check()
      }
      console.log(this.check())
      this.loading = true
      // if (this.check()) {
      rejectOffer(data).then(res => {
        console.log(res)
        console.log(data)
        this.message = ''
        this.reason = ''
        this.dialog = false
        this.$emit('getProfile')
        this.$displaySnackbar({
          message: 'Candidate Rejected!',
          success: true
        })
      })
          .catch(error => {
            console.log(error)
            this.$displaySnackbar({
              message: 'Give a Reason, please',
              success: false
            })
          })
          .finally(() => this.loading = false)
    }
  }

}
</script>

<style scoped>
.eachRoute {
  cursor: pointer;
}

span {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.reject-candidate {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}

.offer-content {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
  margin-bottom: 20px;
  text-transform: capitalize;
}
</style>