<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="500"
    >
      <v-card class="wrapper " >
        <h1 class="title tw-mb-6">
          Interview Details
        </h1>
        <div class="details md:tw-pr-20 tw-p-3">
          <div class="tw-flex">
            <div class="tw-mr-4">
              <vue-feather type="info"></vue-feather>
            </div>
            <div>
              <h2 class="name">{{name}} has not accepted interview schedule.</h2>
              <div class="tw-flex tw-mt-4 md:tw-flex-row tw-flex-col tw-justify-between summary">
                <div>
                  <h3>DATE</h3>
                  <span>{{formatDate}}</span>
                  <h5 class="tw-py-3">Mode: {{detail.type}}</h5>
                </div>
                <div>
                  <h3>TIME</h3>
                  <span>{{formatTime}}</span>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="tw-my-6">
          <span class="name">Invited By</span>
          <div class="tw-flex ">
            <v-avatar color="#EEF8FF" size="30" v-if="detail.invited_by" class="initials">{{detail.invited_by.charAt(0) +''+ detail.invited_by.charAt(1)}}</v-avatar>
            <span class="interviewer_name tw-ml-4">{{detail.invited_by}}</span>
          </div>
        </div>
        <v-card-actions class="tw-justify-between md:tw-flex-row tw-flex-col">
          <BaseButton @click="$emit('open-interview')" button-text="Invite Interviewer" :text="true"></BaseButton>

          <div class="tw-flex">
            <BaseButton @click="$emit('close')" button-text="Cancel" :outlined="true" class="tw-mr-2"></BaseButton>
            <BaseButton :loading="loading" width="143px" @click="resendInvite" button-text="Resend Invite"  :depressed="true"></BaseButton>

          </div>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import moment from 'moment'
import {resendInterviewInvite} from "@/services/api/APIService";
export default {
name: "InterviewScheduleSummaryAndStatus",
  components: {BaseButton},
  data(){
    return{
      loading: false,
    }
  },
  props:{
    dialog:[Boolean],
    name:[String],
    detail:[Object]
  },
  computed:{
    formatDate(){
      return moment(this.detail.date).format('MMMM Do YYYY');
    },
    formatTime(){
      return moment(this.detail.time, "HH:mm:ss").format("hh:mm A");
    }
  },
  methods: {
    resendInvite(){
      this.loading = true
      let id = this.detail.id
      console.log(this.detail.id)
      resendInterviewInvite(id)
          .then(res=> {
            console.log(res.data)
            this.id = res.data
            this.$displaySnackbar({
              message: res.data.detail,
              success: true
            })
          })
          .catch(err=>{
            this.$displaySnackbar({
              message: err.response.detail,
              success: false
            })
          })
          .finally(()=>this.loading=false)
    }
  }
}
</script>

<style scoped>
.wrapper{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
  padding: 15px;
}
.title{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2B1C1C;

}
.details{
  background: #EEF8FF;
  border-radius: 8px;
  /*width: 464px;*/
  /*height: 160px;*/
}
.name{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.summary h3{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1E323F;
  padding-top: 8px;
}
.summary span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
  padding-bottom: 10px;

}
.summary h5{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.initials{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #008EEF;
}
.interviewer_name{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #475661;
}
</style>
