<template>
  <div>
    <v-dialog
        v-model="dialog1"
        persistent
        width="500"
    >
      <v-card class="wrapper " >
        <InviteAdminForInterview :details="details" @close="$emit('close')" @close-interview="closeInvite"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InviteAdminForInterview from "@/components/interview/InviteAdminForInterview";
export default {
name: "InviteInterviewModal",
  components: {InviteAdminForInterview},
  props:{
    dialog1:[Boolean],
    details:[Object]
  },
  methods:{
    closeInvite(){
      this.$emit('close-interview')

    },
  }
}
</script>

<style scoped>
.wrapper{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
  padding: 15px;
}

</style>
